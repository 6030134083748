/* eslint no-console: 0 */

import Vue from 'vue'
import PaymentForm from '../payment/form.js'

document.addEventListener('turbolinks:load', () => {
  let formDefined = document.getElementById("payment-form");
  if(formDefined){
    new Vue(PaymentForm);
  }
})
